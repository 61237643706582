import {Module} from 'vuex'
import IChat from '@/typings/IChat'
import IChatMessage from '@/typings/IChatMessage'
import Requester from '@/requester/Requester'
import {ICompletionRequest} from '@/typings/ICompletionRequest'
import IDefaultMessage from '@/typings/IDefaultMessage'
import {DEFAULT_AI_TYPE} from '@/typings/types'
import {Vue} from 'vue-property-decorator'
import {IChatbot} from '@/typings/IChatbot'
import {IListResponse, IListResponseMeta} from '@/requester/typings'
import {getLocalStorage, saveLocalStorage} from '@/store/utils'
import {IHistoryItem} from '@/typings/IHistoryItem'
import {uniqBy} from 'lodash'
import IFollowUpMessage from '@/typings/IFollowUpMessage'
import {findPartGroupsByType, parseResponse} from '@/pages/Chat/Parts/utils'
import {v4} from 'uuid'
import {IActor} from '@/typings/IActor'
import {fixMessage} from '@/utils'

export interface IChatModule {
    chats: Record<string, IChat>
    delayedChat: any // chat payload todo
    currentChatId: number | null
    defaultMessages: Array<IDefaultMessage>
    currentLoadingMessages: Record<string, {message: string, historyId: number | null}>
    currentLoadingMessagesLoadedStatus: Record<string, boolean>
    waitForResponse: boolean
    blockSendMessage: boolean
    latestChatsMeta: Record<number, IListResponseMeta>
    latestChatListMeta: IListResponseMeta | null
    currentActor: number
    needReloadChats: boolean
    followUpMessages: Record<string, {requestId: string, loaded: boolean, messages: Array<IFollowUpMessage>}>
}

export const chat: Module<IChatModule, any> = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        state: state => state,
        defaultMessages: state => state.defaultMessages,
        chats: state => state.chats ?? {},
        currentChat: state => state.currentChatId ? (state.chats ?? {})[state.currentChatId] : null,
        currentChatId: state => state.currentChatId ?? getStorageState().currentChatId ?? null,
        waitForResponse: state => state.waitForResponse ?? false,
        blockSendMessage: state => state.blockSendMessage ?? false,
        currentLoadingMessages: state => state.currentLoadingMessages ?? {},
        currentLoadingMessagesLoadedStatus: state => state.currentLoadingMessagesLoadedStatus ?? {},
        latestChatsMeta: state => state.latestChatsMeta ?? null,
        followUpMessages: state => state.followUpMessages ?? null,
    },
    mutations: {
        latestChatsMeta(state, payload: {id: number, meta: IListResponseMeta}) {
            Vue.set(state.latestChatsMeta, payload.id, payload.meta)
        },

        currentLoadingMessages(state, payload: {chatId: string, message: {message: string, historyId: number}}) {
            Vue.set(state.currentLoadingMessages, payload.chatId, payload.message)
        },

        blockSendMessage(state, value: boolean) {
            state.blockSendMessage = value
        },

        defaultMessages(state, value: Array<IDefaultMessage>) {
            state.defaultMessages = value
        },

        waitForResponse(state, value: boolean) {
            state.waitForResponse = value
        },

        clearAllChats(state) {
            state.chats = {}
            state.currentChatId = null
            saveStorage(state)
        },

        selectChat(state, id: number) {
            state.currentChatId = id
            saveStorage(state)
        },
    },
    actions: {
        async pullWaitedMessage({state, dispatch}, id: string) {
            if (!state.currentLoadingMessages[id]) {
                return
            }

            const length = state.chats[id].messages.length
            state.chats[id].messages[length - 1].id = `prompt_${state.currentLoadingMessages[id].historyId}`

            state.chats[id].messages.unshift({
                id: `answer_${state.currentLoadingMessages[id].historyId}`,
                role: 'AI',
                message: state.currentLoadingMessages[id].message
            })
            Vue.delete(state.currentLoadingMessages, id)

            saveStorage(state)

            await dispatch('makeFollowUpQuestions', {
                chatId: id
            })
        },

        async clearChat({state}, id: string) {
            const {type} = await Requester.delete(`chat/chatbots/${id}/history/clear`)
            if (type === 'error') {
                return
            }

            state.chats[id].messages = []
            state.chats[id].name = 'New Chat'

            await Requester.patch(`chat/chatbots/${id}`, {
                name: 'New Chat'
            })
        },

        async deleteChat({state, commit, dispatch}, id: number) {
            const {type} = await Requester.delete(`chat/chatbots/${id}`)

            if (type === 'error') {
                return
            }

            const keys = Object.keys(state.chats)

            if (keys.length === 1) {
                await dispatch('createChat')
            } else {
                if (state.currentChatId === id) {
                    commit('selectChat', Number(keys.filter(v => v !== id.toString())[0]))
                }
            }

            state.needReloadChats = true
            Vue.delete(state.chats, id)
        },

        async loadChatHistory({state, commit, dispatch}, chatId: number) {
            if (!state.chats[chatId]) {
                throw new Error(`Chat ${chatId} not loaded`)
            }

            const latestMeta = state.latestChatsMeta[chatId]

            const isFirstLoad = !latestMeta

            if (latestMeta && latestMeta.currentPage >= latestMeta.pageCount) {
                return
            }

            const res = await Requester.get<IListResponse<IHistoryItem>>(
                `chat/chatbots/${chatId}/history`,
                {
                    page: latestMeta ? latestMeta.currentPage + 1 : 1,
                }
            )

            if (res.type === 'error') {
                return
            }

            const {items, _meta} = res.response.data

            commit('latestChatsMeta', {id: chatId, meta: _meta})

            for (const item of items) {
                // TODO delete check
                state.chats[chatId].messages.push({
                    id: `answer_${item.id}`,
                    role: 'AI',
                    message: item.answer
                })

                state.chats[chatId].messages.push({
                    id: `prompt_${item.id}`,
                    role: 'Human',
                    message: item.prompt
                })
            }

            state.chats[chatId].messages = uniqBy(state.chats[chatId].messages, v => v.id)

            if (isFirstLoad) {
                dispatch('makeFollowUpQuestions', {chatId})
            }
        },

        async loadChats({state, commit, dispatch}) {
            if (state.latestChatListMeta && state.latestChatListMeta.currentPage === state.latestChatListMeta.pageCount) {
                return
            }

            const firstLoad = state.latestChatListMeta === null

            const allItems = []

            if (state.latestChatListMeta && state.needReloadChats) {
                for (let i = 1; i <= state.latestChatListMeta.currentPage; i++) {
                    const res = await Requester.get<IListResponse<IChatbot>>(
                        'chat/chatbots',
                        {
                            page: i
                        }
                    )

                    if (res.type === 'error') {
                        continue
                    }

                    allItems.push(...res.response.data.items)
                }
            }

            const res = await Requester.get<IListResponse<IChatbot>>(
                'chat/chatbots',
                {
                    page: state.latestChatListMeta ? state.latestChatListMeta.currentPage + 1 : 1
                }
            )

            if (res.type === 'error') {
                return
            }

            const {items, _meta} = res.response.data

            allItems.push(...items)

            state.latestChatListMeta = _meta

            let hasSelectedChat = false

            for (const item of allItems) {
                if (state.currentChatId === item.id) {
                    hasSelectedChat = true
                }

                Vue.set(state.chats, item.id,
                {
                    ...getDefaultChat(),
                    ...(state.chats[item.id] ?? {}),
                    ...item
                })
            }

            if (state.delayedChat) {
                await dispatch('createChat', state.delayedChat)
                state.currentChatId
                state.delayedChat = null
            } else if (!state.currentChatId || items.length === 0) {
                if (items.length > 0) {
                    state.currentChatId = items[0].id
                } else {
                    await dispatch('createChat')
                }
            } else if (!hasSelectedChat && firstLoad) {
                state.currentChatId = items[0].id
            }

            saveStorage(state)
        },

        async delayedCreateChat({state}, settings?: {actor: IActor}) {
            state.delayedChat = settings
            saveStorage(state)
        },

        async createChat({state}, settings?: {actor: IActor}) {
            const chatbotSettings = {
                name: 'New Chat',
                actor_id: state.currentActor, // TODO fix me
                prompt_prefix: 'I want you act as Chat AI'
            }

            if (settings?.actor) {
                chatbotSettings.name = `Chat with ${settings.actor.act_as}`
                chatbotSettings.actor_id = settings.actor.id
                chatbotSettings.prompt_prefix = settings.actor.prompt_prefix
            }

            const res = await Requester.post('chat/chatbots', chatbotSettings)

            if (res.type === 'error') {
                return
            }

            const chatbot = res.response.data as IChatbot

            Vue.set(state.chats, chatbot.id, {
                ...chatbot,
                ...getDefaultChat()
            })

            state.currentChatId = chatbot.id
            saveStorage(state)
        },

        async loadFollowUpQuestions({}) {

        },

        async makeFollowUpQuestions({state, commit}, payload: {chatId: string}) {
            const chatMessages = state.chats[payload.chatId]?.messages

            if (!chatMessages || chatMessages.length === 0) {
                return
            }

            const requestId = v4()
            Vue.set(state.followUpMessages, payload.chatId, {requestId, loaded: false, messages: []})

            const followUpMessage = await requestCompletions(
                `Make 3 short follow-up questions for this (one interesting, one funny and one personal): ${chatMessages[0].message}`
                    .slice(0, FREE_CHAT_HISTORY_LIMIT)
            )

            if (!followUpMessage) {
                Vue.delete(state.followUpMessages, payload.chatId)
                return
            }

            const parsedResponse = parseResponse(fixMessage(followUpMessage))

            let messages = []

            try {
                let parsedMessages: Array<string> = []

                const listBlocks = findPartGroupsByType(parsedResponse, ['dot-list', 'number-list', 'text-list'])

                if (listBlocks.length > 0) {
                    parsedMessages = listBlocks[0].texts
                } else {
                    parsedMessages = parsedResponse
                        .slice(-3)
                        .map(v => v[0].texts[0])

                    if (parsedMessages.length < 3) {
                        parsedMessages = []
                    }
                }

                if (parsedMessages.length < 3) {
                    parsedMessages = []
                }

                messages =
                    [
                        'Continue',
                         ...parsedMessages
                            .map(v => v.replace(/^[\s\S]+:\s?/, '')),
                    ]
            } catch {
                messages = [
                    'Continue'
                ]
            }

            if (!(payload.chatId in state.followUpMessages)) {
                return
            }

            if (state.followUpMessages[payload.chatId].requestId !== requestId) {
                return
            }

            Vue.set(state.followUpMessages, payload.chatId,
                {
                    loaded: true,
                    messages
                })
        },

        //async loadDefaultMessages({state, commit}) {
        //    commit('waitForResponse', true)
        //    state.defaultMessages = [
        //        {
        //            id: 1,
        //            static_text: 'Hi! Just ask me about any topic you wish and give me specific requirements.',
        //        }
        //    ]
        //    //state.defaultMessages = (await Requester.get<{items: Array<IDefaultMessage>}>('chat/default-messages')).items
        //    commit('waitForResponse', false)
        //},

        async makeChatName({state, commit}, payload: {chatId: string, message: string}) {
            const currentChatName = await requestCompletions(`Generate short title from this message - "${payload.message}"`)

            if (!currentChatName) {
                return
            }

            state.chats[payload.chatId].name = currentChatName

            const res = await Requester.patch<IChatbot>(`chat/chatbots/${payload.chatId}`, {
                name: currentChatName
            })

            if (res.type === 'error') {
                return
            }

            state.chats[payload.chatId].name = res.response.data.name
            saveStorage(state)
        },

        async sendMessageStream({state, commit, dispatch}, payload: {id: number, message: IChatMessage}) {
            if (state.blockSendMessage || state.waitForResponse) {
                return
            }

            Vue.delete(state.followUpMessages, payload.id)

            let firstMessage = state.chats[payload.id].messages.length === 0

            state.chats[payload.id].messages.unshift({
                ...payload.message,
            })
            saveStorage(state)
            commit('waitForResponse', true)
            const res = await Requester.streamPost('completions-stream',
                {
                    prompt: payload.message.message,
                    chatbot_id: payload.id,
                } as ICompletionRequest,
                {
                    is_subscribed: 1
                }
                )

            if (res.type === 'error') {
                throw new Error(JSON.stringify(res))
            }

            const stream = res.response

            let currentMessage = ''
            commit('currentLoadingMessages', {chatId: payload.id, message: currentMessage})
            commit('waitForResponse', false)
            Vue.set(state.currentLoadingMessagesLoadedStatus, payload.id, false)

            let historyId: number | null = null

            const reader = stream.getReader()
            while(true) {
                const part = await reader.read()

                const result = new TextDecoder()
                    .decode(part.value as Uint8Array)
                    .replace('[DONE]', '')

                currentMessage += result

                const match = currentMessage.match(/\[HISTORY_ID:\d+\]/)
                if (match) {
                    historyId = parseInt(match[0].match(/\d+/)![0])
                    currentMessage = currentMessage.replace(match[0], '')
                }

                commit('currentLoadingMessages', {chatId: payload.id, message: {message: currentMessage, historyId: historyId}})
                if (part.done) {
                    break
                }
            }

            Vue.set(state.currentLoadingMessagesLoadedStatus, payload.id, true)

            if (firstMessage) {
                await dispatch('makeChatName', {
                    chatId: payload.id,
                    message: payload.message.message
                })
            }

            saveStorage(state)
        },
        async sendMessage({state, commit, dispatch}, payload: {id: number, message: IChatMessage}) {
            if (state.blockSendMessage || state.waitForResponse) {
                return
            }

            Vue.delete(state.followUpMessages, payload.id)

            let firstMessage = state.chats[payload.id].messages.length === 0

            state.chats[payload.id].messages.unshift({
                ...payload.message,
            })
            saveStorage(state)
            commit('waitForResponse', true)
            const res = await Requester.post<{message: string, history_id: number}>('completions',
                {
                    prompt: payload.message.message,
                    chatbot_id: payload.id,
                } as ICompletionRequest,
                {
                    is_subscribed: 1
                }
            )

            if (res.type === 'error') {
                throw new Error(JSON.stringify(res))
            }

            const {message, history_id} = res.response.data

            commit('currentLoadingMessages', {chatId: payload.id, message: {message, historyId: history_id}})
            commit('waitForResponse', false)
            Vue.set(state.currentLoadingMessagesLoadedStatus, payload.id, true)

            await dispatch('pullWaitedMessage', payload.id)

            if (firstMessage) {
                await dispatch('makeChatName', {
                    chatId: payload.id,
                    message: payload.message.message
                })
            }

            saveStorage(state)

            commit('blockSendMessage', false)
        },
        $reset({state}) {
            const defaultState = getDefaultState()

            for (const key in defaultState) {
                //@ts-ignore
                state[key] = defaultState[key]
            }
        }
    }
}

async function requestCompletions(message: string) {
    const res = await Requester.post<{message: string}>('completions',
        {
            prompt: message
        }
    )
    if (res.type === 'success') {
        return res.response.data.message
    }
}

function getDefaultState(): IChatModule {
    return {
        chats: {},
        delayedChat: null,
        currentChatId: getStorageState().currentChatId ?? null,
        currentLoadingMessagesLoadedStatus: {},
        currentLoadingMessages: {},
        defaultMessages: [],
        waitForResponse: false,
        blockSendMessage: false,
        currentActor: 149,
        latestChatsMeta: {},
        latestChatListMeta: null,
        needReloadChats: false,
        followUpMessages: {},
    }
}

function makeChatHistory(chat: IChat) {
    let resultMessage = ''

    const messages = chat.messages

    for (let i = 0; i < messages.length; i++) {
        const roleName = getRoleName(messages[i])
        const currentLimit = FREE_CHAT_HISTORY_LIMIT - roleName.length - resultMessage.length - 1
        const message = messages[i].message.slice(-currentLimit)
        resultMessage = [`${roleName}${message}`, resultMessage].filter(v => v).join('\n')

        if (resultMessage.length >= FREE_CHAT_HISTORY_LIMIT) {
            break
        }
    }

    return resultMessage
}

function getDefaultChat() {
    return {
        messages: []
    }
}

function getRoleName(message: IChatMessage) {
    return message.role === 'Human' ? 'Human: ' : `${DEFAULT_AI_TYPE}: `
}

function getStorageState(): Partial<IChatModule> {
    return getLocalStorage<IChatModule>('chat')
}

function saveStorage(state: IChatModule) {
    saveLocalStorage('chat', {
        currentChatId: state.currentChatId,
        delayedChat: state.delayedChat
    })
}

const FREE_CHAT_HISTORY_LIMIT = 700